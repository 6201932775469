import axios from "axios";

export default {
    async getEvents(user_id: string, dateStart: string, dateEnd: string) {
        const data = new FormData();
        data.append("user_id", user_id);
        data.append("date_start", dateStart);
        data.append("date_end", dateEnd);      

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/events_calendar/app/getEvents`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}events_calendar/app/getEvents`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,  
            },
        });

        return response;
    },

    async deleteEvent(event_id: string) {
        //const response = await axios.get(`http://192.168.11.159/firegui_crm_h2/rest/v1/delete/events/${event_id}`, {
        const response = await axios.get(`rest/v1/delete/events/${event_id}`, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });

        return response;
    },

    async setConfirmation(event_id: string, user_id: string, status: string) {
        const data = new FormData();
        data.append("event_id", event_id);
        data.append("user_id", user_id);
        data.append("status", status);

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/events_calendar/app/setConfirmation`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}events_calendar/app/setConfirmation`, data, {
                headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });

        return response;
    },

    async getFutureEvent(user_id: string) {
        const data = new FormData();
        data.append("user_id", user_id);     

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/events_calendar/app/getFutureEvents`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}events_calendar/app/getFutureEvents`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,  
            },
        });

        return response;
    },
};
